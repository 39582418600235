.Countdown{
	margin: 10px auto;
	padding-bottom: 20px;
}

.Countdown-col{
	display: inline-block;
}

.Countdown-col-element{
	display: inline-block;
	margin: 0 20px;
	display: flex;
	flex-direction: column;
}

.Countdown-col-element strong{
	font-size: calc(10px + 6vmin);
}

.Countdown-col-element span{
	font-size: small;
}