.App {
  text-align: center;
}

.App-header {
  background-color: #BC3440;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1;
}


.App-text {
  font-weight: bold;
  line-height: 1.2;
  padding: 10px 15px;
  text-align: justify;
  max-width: 100vh;
}