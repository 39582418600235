body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #BC3440;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1 1;
}


.App-text {
  font-weight: bold;
  line-height: 1.2;
  padding: 10px 15px;
  text-align: justify;
  max-width: 100vh;
}
.Countdown{
	margin: 10px auto;
	padding-bottom: 20px;
}

.Countdown-col{
	display: inline-block;
}

.Countdown-col-element{
	display: inline-block;
	margin: 0 20px;
	display: flex;
	flex-direction: column;
}

.Countdown-col-element strong{
	font-size: calc(10px + 6vmin);
}

.Countdown-col-element span{
	font-size: small;
}
